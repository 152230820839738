import { forwardRef, useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AlertDialogTitle, AlertDialogDescription } from '@radix-ui/react-alert-dialog';
import {
  Button,
  Heading,
  Paragraph,
  Radio,
  Modal as ModalComponent,
  IconButton,
} from '@hexa-ui/components';

import * as S from './DownloadModal.styles';
import { useDownloadModal } from './hooks/useDownloadModal';
import { OptionProps } from './types';
import { Box } from '@material-ui/core';
import { ModalContext } from 'contexts';
import { X } from '@hexa-ui/icons';

export const DownloadModal = forwardRef<HTMLDivElement>((props, ref) => {
  const firstInteractiveElementRef = useRef<HTMLButtonElement>(null);
  const { onSubmit, fileFormat, handleSelectFileFormat, handleSubmit, isDirty, isValid } =
    useDownloadModal();
  const { formatMessage } = useIntl();

  const { Root: Modal } = ModalComponent;

  const { downloadModal } = useContext(ModalContext);

  const handleClose = () => {
    downloadModal.setOpen(false);
  };

  useEffect(() => {
    if (downloadModal.isOpen) {
      firstInteractiveElementRef.current?.focus();
    }
  }, [downloadModal.isOpen]);

  return (
    <S.DownloadModalModalWrapper ref={ref}>
      <Modal
        open={downloadModal.isOpen}
        actions={null}
        title={
          <S.DownloadModalTitleWrapper>
            <AlertDialogTitle>
              {formatMessage({ id: 'downloadModal.titleHeader' })}
            </AlertDialogTitle>
            <IconButton onClick={handleClose} size="medium" variant="tertiary" icon={X} />
          </S.DownloadModalTitleWrapper>
        }
        aria-describedby="modal-description"
      >
        <Box marginTop="4px">
          <Paragraph>{formatMessage({ id: 'downloadModal.description' })}</Paragraph>
        </Box>
        <Box marginTop="15px">
          <Heading size="H5">{formatMessage({ id: 'downloadModal.fieldFileFormat' })}</Heading>
          <Box>
            <Radio.Root>
              {fileFormat.map((item: OptionProps, index: number) => (
                <Radio.Item
                  key={item.name}
                  label={item.name}
                  onClick={() => handleSelectFileFormat(item.name)}
                  value={item.key}
                  id={`${item.value}`}
                  checked={item.name === fileFormat[index].name}
                />
              ))}
            </Radio.Root>
          </Box>
        </Box>
        <S.WrapperButtons>
          <Button
            ref={firstInteractiveElementRef}
            size="medium"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid && isDirty}
          >
            {formatMessage({ id: 'downloadModal.sendButton' })}
          </Button>
          <Button size="medium" variant="secondary" onClick={() => downloadModal.setOpen(false)}>
            {formatMessage({ id: 'downloadModal.cancelButton' })}
          </Button>
        </S.WrapperButtons>
      </Modal>
    </S.DownloadModalModalWrapper>
  );
});
