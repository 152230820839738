import { Button } from '@hexa-ui/components';
import { Calendar, Send } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';

import * as S from './CustomButton.styles';

// INFO: this component can be evolved to receive items dynamically
const CustomButton = ({
  children,
  isSubmenuOpen = false,
  onSendReportClick,
  onScheduleClick,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <S.WrapperCustomButton>
      <Button {...props}>{children}</Button>
      {isSubmenuOpen && (
        <S.SubmenuWrapper>
          <S.SubmenuItem onClick={onSendReportClick}>
            <Send />
            {formatMessage({ id: 'resolvedRequestsPage.sendReport' })}
          </S.SubmenuItem>
          <S.SubmenuItem onClick={onScheduleClick}>
            <Calendar />
            {formatMessage({ id: 'resolvedRequestsPage.schedule' })}
          </S.SubmenuItem>
        </S.SubmenuWrapper>
      )}
    </S.WrapperCustomButton>
  );
};

export default CustomButton;
