import { getCreditdataQuery } from './getGatewayReportDataResolver';
import { getScheduledCountryQuery } from './getSchedulesCountry';

// new queries
import { GET_GATEWAY_REPORT_DATA } from './getGatewayReportData';

export { getCreditdataQuery, getScheduledCountryQuery };

export default {
  GET_GATEWAY_REPORT_DATA,
};
