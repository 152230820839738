import { EmptyState, Table } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { TableRequestProps } from 'types/tableRequest';

const TableRequest = ({
  type,
  data,
  loading,
  onSort,
  totalResults,
  filtersPagination,
  setFiltersPagination,
  isError,
  commonColumns,
}: TableRequestProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const getPaginationConfig = (pageRequest: string) => ({
    total: totalResults,
    pageSizeOptions: [],
    current: filtersPagination.page,
    pageSize: filtersPagination.pageSize,
    showQuantityIndicator: true,
    showPageSizeSelector: false,
    pageSizeOptionsIntl: (option) =>
      formatMessage({ id: `${pageRequest}.pagination.pageSizeOptions` }, { options: option }),
    onChange: (page, pageSize) =>
      setFiltersPagination((prevState) => ({ ...prevState, page, pageSize })),
  });

  const infoTable = {
    Resolved: {
      emptyMessage: formatMessage({ id: 'pendingRequestsPage.emptyMessage' }),
      dataTestId: 'transactions-table',
      columns: commonColumns,
      pagination: getPaginationConfig('resolvedRequestsPage'),
    },
  };

  return (
    <Table
      fixedHeader
      onSort={onSort}
      loadingMessage=""
      emptyMessage={
        isError ? (
          <EmptyState.SectionLevel
            description={formatMessage({ id: 'resolvedRequestsPage.errorMessage' })}
          />
        ) : (
          <span>{infoTable[type].emptyMessage}</span>
        )
      }
      tableHeight="100%"
      tableWidth="100%"
      data={data}
      loading={loading}
      data-testid={infoTable[type].dataTestId}
      onRow={infoTable[type].onRow}
      columns={infoTable[type].columns}
      pagination={{
        ...infoTable[type].pagination,
        quantityIndicatorIntl: formatMessage({ id: 'pagination.of' }),
      }}
    />
  );
};
export default TableRequest;
