import { format, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

export const createHourOption = (hour: number) => {
  const date = setMilliseconds(setSeconds(setMinutes(setHours(new Date(), hour), 0), 0), 0);
  return {
    id: format(date, 'HH:mm'),
    name: format(date, 'h a'),
    placeholderName: format(date, 'h a'),
  };
};
