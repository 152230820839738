export const columnsHideShowDropDown = [
  {
    key: 'PAYMENT_ID',
    label: 'Payment ID',
  },
  {
    key: 'ORDER_NUMBER',
    label: 'Order ID',
  },
  {
    key: 'ORDER_VALUE',
    label: 'Order value',
  },
  {
    key: 'SELLER_ID',
    label: 'Seller ID',
  },
  {
    label: 'Invoice date',
    key: 'INVOICE_DATE',
  },
  {
    key: 'ACCOUNT_ID',
    label: 'POC ID',
  },
  {
    key: 'INVOICE_NUMBER',
    label: 'Invoice ID',
  },
  {
    key: 'PAYMENT_VALUE',
    label: 'Payment value',
  },
  {
    key: 'PAYMENT_STATUS',
    label: 'Payment status',
  },
  {
    key: 'PAYMENT_METHOD',
    label: 'Method',
  },
  {
    key: 'PAYMENT_METHOD_CODE',
    label: 'Service provider',
  },
  {
    key: 'ORDER_STATUS',
    label: 'Order status',
  },
  {
    key: 'INVOICE_VALUE',
    label: 'Invoice value',
  },
  {
    key: 'INVOICE_STATUS',
    label: 'Invoice status',
  },
  {
    key: 'PAYMENT_DATE',
    label: 'Payment date',
  },
  {
    label: 'Beneficiary name',
    key: 'BENEFICIARY_NAME',
  },
  {
    label: 'Beneficiary ID',
    key: 'BENEFICIARY_ID',
  },
  {
    label: 'Payment method fee',
    key: 'PAYMENT_METHOD_FEE',
  },
  {
    label: 'Commission fee',
    key: 'COMMISSION_FEE',
  },
];
