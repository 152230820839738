import { useCallback, useContext, useEffect, useState, useRef, forwardRef } from 'react';
import { AlertDialogTitle } from '@radix-ui/react-alert-dialog';
import * as S from './Schedule.styles';

import { Button, IconButton, Heading, Paragraph, Radio, Select, Modal } from '@hexa-ui/components';

import { Box } from '@material-ui/core';
import { Check, X } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';

import type { OptionProps } from './Schedule.types';
import { useScheduleModal } from './hooks/useScheduleModal';
import { dropdownOptionsHour } from './utils/data';

import { ModalContext } from 'contexts';
import { changeOverflowAuto } from 'utils/functions';

export const ScheduleModal = forwardRef<HTMLDivElement>((props, ref) => {
  const firstInteractiveElementRef = useRef<HTMLButtonElement | null>(null);
  const {
    onSubmit,
    register,
    handleSubmit,
    handleSelectDay,
    handleSelectFileFormat,
    setDownloadHour,
    selectedDays,
    fileFormat,
  } = useScheduleModal();
  const { formatMessage } = useIntl();

  changeOverflowAuto();

  const { scheduleModal } = useContext(ModalContext);

  useEffect(() => {
    if (scheduleModal.isOpen) {
      setTimeout(() => {
        firstInteractiveElementRef.current?.focus();
      }, 0);
    }
  }, [scheduleModal.isOpen]);

  return (
    <S.ScheduleModalModalWrapper ref={ref}>
      <Modal.Root
        open={scheduleModal.isOpen}
        actions={null}
        title={
          <S.ScheduleModalTitleWrapper>
            <AlertDialogTitle>
              {formatMessage({ id: 'scheduleModal.titleHeader' })}
            </AlertDialogTitle>
            <IconButton
              ref={firstInteractiveElementRef}
              onClick={() => {
                scheduleModal.setOpen(false);
              }}
              size="medium"
              variant="tertiary"
              icon={X}
              tabIndex={0}
            />
          </S.ScheduleModalTitleWrapper>
        }
      >
        <S.ScheduleModalHeader>
          <S.ScheduleModalHeaderText>
            <Paragraph>{formatMessage({ id: 'scheduleModal.description' })}</Paragraph>
          </S.ScheduleModalHeaderText>
        </S.ScheduleModalHeader>

        <S.ScheduleModalForm>
          <S.ScheduleFormFieldGroup>
            <Box marginBottom="15px">
              <Heading size="H5">{formatMessage({ id: 'scheduleModal.fieldFileFormat' })}</Heading>
              <S.ScheduleModalFileFormats>
                <Radio.Root>
                  {fileFormat.map((item: OptionProps, index: number) => (
                    <Radio.Item
                      key={item.name}
                      label={item.name}
                      onClick={() => handleSelectFileFormat(item.name)}
                      value={item.key}
                      id={`${item.value}`}
                      checked={item.name === fileFormat[index].name}
                      {...register('fileFormat')}
                    />
                  ))}
                </Radio.Root>
              </S.ScheduleModalFileFormats>
            </Box>

            <Box marginBottom="15px">
              <Heading size="H5">{formatMessage({ id: 'scheduleModal.fieldFrequency' })}</Heading>
              <Paragraph>{formatMessage({ id: 'scheduleModal.subFieldFrequency' })}</Paragraph>
              <S.ScheduleModalFileFormats>
                {selectedDays.map((item: OptionProps, index: number) => (
                  <S.ScheduleModalFileFormatsItem
                    key={index}
                    onClick={() => handleSelectDay(item.name)}
                    selected={item.value}
                    shape="square"
                  >
                    {item.value && <Check />}
                    <Paragraph size="small">{item.name}</Paragraph>
                  </S.ScheduleModalFileFormatsItem>
                ))}
              </S.ScheduleModalFileFormats>
            </Box>

            <Box marginBottom="15px">
              <Heading size="H5">{formatMessage({ id: 'scheduleModal.fieldDelivery' })}</Heading>
              <Select.Root
                {...register('downloadHour')}
                shape="square"
                data-testid="single-select"
                placeholder="Select a time"
                onChange={(e) => setDownloadHour(e)}
              >
                {dropdownOptionsHour.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select.Root>
            </Box>
          </S.ScheduleFormFieldGroup>
        </S.ScheduleModalForm>

        <S.ScheduleModalActions>
          <Button size="medium" onClick={handleSubmit(onSubmit)}>
            {formatMessage({ id: 'scheduleModal.sendButton' })}
          </Button>
          <Button
            size="medium"
            variant="secondary"
            onClick={() => {
              scheduleModal.setOpen(false);
            }}
          >
            {formatMessage({ id: 'scheduleModal.cancelButton' })}
          </Button>
        </S.ScheduleModalActions>
      </Modal.Root>
    </S.ScheduleModalModalWrapper>
  );
});
