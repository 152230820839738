import MessageMap from 'i18n/i18n.d';

const en: MessageMap = {
  Commons: {
    actions: 'Actions',
    edit: 'Edit',
    delete: 'Delete',
  },
  ErrorMessages: {
    DATA_NOT_FOUND: 'Data has not been loaded.',
    ERROR_FETCH_DATA:
      'An error occurred while trying to fetch the data. Refresh the page and try again.',
  },
  PageTitles: {
    WORKFLOW: 'Workflow',
    CREATE: 'Create new Workflow',
    PAYMENTS: 'Payments',
    TRANSACTIONS: 'Transactions',
  },
  pendingRequestsPage: {
    pendingRequest: 'Pending requests',
    results: 'Showing {resultsNumber} results',
    createdOn: 'Created on',
    vendor: 'Vendor',
    country: 'Country',
    tool: 'Tool',
    id: 'ID',
    requester: 'Requester',
    pagination: { pageSizeOptions: 'Items per page: {options}' },
    emptyMessage: "There's no information to show",
    commom: {
      filter: 'Filter',
      search: 'Search',
    },
  },
  resolvedRequestsPage: {
    sendReport: 'Send report',
    schedule: 'Schedule',
    manageScheduled: 'Manage scheduled reports',
    resolvedRequest: 'Resolved requests',
    description: 'Access payment information and send filtered reports to your email.',
    results: 'Showing {resultsNumber} results',
    errorMessage: 'Error loading data',
    title: 'Title',
    status: 'Status',
    createdOn: 'Created on',
    lastEdited: 'Last edited',
    vendor: 'Vendor',
    country: 'Country',
    tool: 'Tool',
    id: 'ID',
    requester: 'Requester',
    approver: 'Approver',
    pagination: { pageSizeOptions: 'Items per page: {options}' },
    commom: {
      filter: 'Filter',
      search: 'Search',
    },
    emptyMessage: "There's no information to show",
    toastMessages: {
      paymentIdCopied: 'Payment ID copied.',
    },
    scheduleNew: {
      titleHeader: 'Schedule',
      description: 'The file containing the filtered data will be sent by email to',
      message:
        'The report file will contain payments started seven days before the selected weekday.',
      fieldFrequency: 'Frequency',
      subFieldFrequency: 'Sent every week on:',
      fieldDelivery: 'Delivery time',
      deliveryTime: 'Select an option',
      fieldReportName: 'Report name',
      filters: 'Filters',
      report: 'Report name',
      placeholder: 'Credit card partially delivered',
      sendButton: 'Schedule',
      cancelButton: 'Go back',
    },
  },
  filter: {
    status: 'Status',
    vendor: 'Vendor',
    vendors: 'Vendors',
    tool: 'Tool',
    tools: 'Tools',
    createdOn: 'Created on',
    lastEdited: 'Last edited',
    startDate: 'Start date',
    endDate: 'End date',
    endDateHint: 'Leave this field empty to search only by the start date.',
    endDateError: 'The end date must be after the start date.',
    invalidDateError: 'Invalid date.',
    clearFilters: 'Clear filters',
    applyFilters: 'Apply',
    cancel: 'Cancel',
    search: 'Search',
    all: 'All',
    paymentMethod: 'Payment method',
  },
  pagination: {
    of: 'of',
  },
  statusOrders: {
    canceled: 'Canceled',
    delivered: 'Delivered',
    expired: 'Expired',
    failed: 'Failed',
    error: 'Error',
    inProgress: 'In progress',
    orderPlaced: 'Order placed',
    outForDelivery: 'Out for delivery',
    paid: 'Paid',
    partiallyDelivered: 'Partially delivered',
    deliveredInfo: 'deliveredInfo',
    expiredInfo: 'expiredInfo',
    paymentPending: 'Payment pending',
    inProgressInfo: 'inProgressInfo',
    orderPlacedInfo: 'orderPlacedInfo',
    outForDeliveryInfo: 'outForDeliveryInfo',
    paidInfo: 'paidInfo',
    partiallyDeliveredInfo: 'partiallyDeliveredInfo',
    paymentPendingInfo: 'paymentPendingInfo',
    errorInfo: 'errorInfo',

    // TODO: checar e remover baixo
    approved: 'Approved',
    processing: 'Processing',
    rejected: 'Rejected',
    pending: 'Pending',
    pendingInfo: 'pendingInfo',
    failedInfo: 'failedInfo',
    processingInfo: 'processingInfo',
    rejectedInfo: 'rejectedInfo',
    approvedInfo: 'approvedInfo',
    canceledInfo: 'canceledInfo',
  },
  manageScheduledReports: {
    title: 'Manage scheduled reports',
    back: 'Back',
  },

  downloadModal: {
    titleHeader: 'Email report now',
    description: 'The file containing the filtered data will be sent to your corporate email.',
    fieldEmail: 'Your email',
    fieldFileFormat: 'File format',
    sendButton: 'Send',
    cancelButton: 'Cancel',
  },
  scheduleModal: {
    titleHeader: 'Schedule delivery',
    description: 'Receive the file in your email',
    fieldFileFormat: 'File format',
    fieldFrequency: 'Frequency',
    subFieldFrequency: 'Recurs every week on:',
    fieldDelivery: 'Delivery time',
    fieldEmail: 'Email',
    sendButton: 'Schedule',
    cancelButton: 'Cancel',
  },
  reportsPage: {
    pageTitle: 'Reports',
    downloadButton: 'Download',
    scheduleButton: 'Schedule',
    filterButton: 'Filter',
    table: {
      header: {
        date: 'Date',
        description: 'Description',
        amount: 'Amount',
        status: 'Status',
        actions: 'Actions',
      },
    },
  },
  homePage: {
    pageTitle: 'Welcome to Payments Hub',
  },
  workflow: {
    optionalButton: {
      label: 'Payment method',
    },
  },
  createWorkflowPage: {
    publishWorkflow: 'Publish',
    paymentMethod: 'Payment method:',
    sideMenu: {
      label: 'Select a provider',
      addProviderButton: 'Add provider',
      emptySelect: 'Please select a provider before adding.',
      providerAlreadyAdded: 'Provider {providerName} already added',
      errorMessage: 'No adapters available. Select a different country.',
    },
    providerCard: {
      succeeded: 'Succeeded',
      declined: 'Declined',
      error: 'Error',
    },
    connectProvidersDialog: {
      createTitle: 'Create adapter',
      editTitle: 'Edit adapter',
      tooltip: 'Duplicate',
      inputPlaceholder: 'Target value',
      button: {
        primary: 'Save',
        secondary: 'Cancel',
      },
      errorFetchingPayload: 'Error fetching provider payload.',
      invalidInput: 'Invalid input.',
      noPayloadAvailable: 'No payload available.',
    },
    removeProviderDialog: {
      title: 'Remove provider',
      content: 'Are you sure you want to remove this provider?',
      button: {
        primary: 'Remove',
        secondary: 'Cancel',
      },
    },
    removeEdgeDialog: {
      title: 'Remove connection',
      content: 'Are you sure you want to remove this connection?',
      button: {
        primary: 'Remove',
        secondary: 'Cancel',
      },
    },
    editOrRemoveDialog: {
      title: 'Edit or remove?',
      content: 'Would you like to edit the connection between the two providers or remove it?',
      button: {
        primary: 'Edit',
        secondary: 'Remove',
      },
    },
  },
};

export default en;
