export const filters = [
  {
    id: 1,
    title: 'Country',
    description: 'Brazil',
  },
  {
    id: 2,
    title: 'Seller',
    description: 'Mondelez',
  },
  {
    id: 3,
    title: 'Method',
    description: 'Credit card',
  },
  {
    id: 4,
    title: 'Service provider',
    description: 'Mastercard, Visa',
  },
  {
    id: 6,
    title: 'Status',
    description: 'Paid',
  },
  {
    id: 7,
    title: 'Columns',
    description:
      'Payment ID, Creation date, POC ID, Seller ID, Order ID, Order status, Order value, Payment status, Payment value, Method, Service provider, Payment date.',
  },
];
