import { Select } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { TimeProps } from 'types/time';
import * as S from './Time.styles';

export const Time = ({ options, ...rest }: TimeProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <S.Container>
      <Select.Root
        width={194}
        shape="square"
        data-testid="single-select"
        placeholder={formatMessage({ id: 'resolvedRequestsPage.scheduleNew.deliveryTime' })}
        {...rest}
      >
        {options.map((item, index) => (
          <Select.Option key={index} value={item.name}>
            {item.name}
          </Select.Option>
        ))}
      </Select.Root>
    </S.Container>
  );
};
