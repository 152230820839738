import { Tooltip } from '@hexa-ui/components';
import { StatusProps } from 'types/status';
import { Container, StatusText, StyledStatus } from './Status.styles';
import { useStatusBase } from './hooks';

const Status = ({ hasTooltip = true, fontSizeStatus = '1rem', type }: StatusProps): JSX.Element => {
  const statusBase = useStatusBase();
  const status = statusBase[type] || {
    variant: 'failed',
    statusName: `Not mapped: [${type}]`,
    tooltipMessage: 'Status not mapped',
  };
  const { variant, tooltipMessage, statusName } = status;

  if (!variant) {
    throw new Error(`Invalid status type: ${type}`);
  }

  return (
    <Container variant={variant}>
      {hasTooltip ? (
        <Tooltip text={tooltipMessage} placement="top">
          <StyledStatus variant={variant} />
        </Tooltip>
      ) : (
        <StyledStatus variant={variant} />
      )}
      <StatusText css={{ fontSize: fontSizeStatus }}>{statusName}</StatusText>
    </Container>
  );
};
export { Status };
