import { Input } from '@hexa-ui/components';
import * as S from './Report.styles';

export const Report = ({ label = 'Label', placeholder = '', ...props }): JSX.Element => {
  return (
    <S.Container>
      <Input
        width="409px"
        id="input-with-label"
        label={label}
        placeholder={placeholder}
        data-testid="report-container"
        {...props}
      />
    </S.Container>
  );
};
