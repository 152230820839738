import { Button, Heading } from '@hexa-ui/components';
import { useIntl } from 'react-intl';

import * as S from './Header.styles';
import { CustomButton } from 'components/CustomButton';
import { ChevronDown, ChevronUp } from '@hexa-ui/icons';
import { useState } from 'react';
import { IHeaderProps } from 'types/header';

/* istanbul ignore next */
const ChevronUpIcon = () => <ChevronUp />;
const ChevronDownIcon = () => <ChevronDown />;

// INFO: este componente pode ser evoluído
export const Header = ({
  handleFirstButton,
  handleSubmenuFirstItem,
  handleSubmenuSecondItem,
  showButtons = false,
  title,
  description,
  defaultButtonLabel,
  customButtonLabel,
}: IHeaderProps) => {
  const { formatMessage } = useIntl();
  const [changeIcon, setChangeIcon] = useState(false);

  const toggleChangeIcon = () => {
    setChangeIcon((prevState) => !prevState);
  };

  return (
    <S.WrapperHeader className="wrapper-header">
      <S.WrapperAlignTitle className="wrapper-align-title">
        <Heading headers="h2">{formatMessage({ id: title })}</Heading>
        {description && <S.Description>{formatMessage({ id: description })}</S.Description>}
      </S.WrapperAlignTitle>
      {showButtons && (
        <S.WrapperAlignButtons className="wrapper-align-buttons">
          <Button size="medium" trailing variant="secondary" onClick={handleFirstButton}>
            {formatMessage({ id: defaultButtonLabel })}
          </Button>
          <CustomButton
            onSendReportClick={handleSubmenuFirstItem}
            onScheduleClick={handleSubmenuSecondItem}
            isSubmenuOpen={changeIcon}
            icon={changeIcon ? ChevronUpIcon : ChevronDownIcon}
            size="medium"
            trailing
            variant="secondary"
            onClick={toggleChangeIcon}
          >
            {formatMessage({ id: customButtonLabel })}
          </CustomButton>
        </S.WrapperAlignButtons>
      )}
    </S.WrapperHeader>
  );
};
