import { SearchField } from '@hexa-ui/components';
import { useState } from 'react';
import { useIntl } from 'react-intl';

const Search = ({ onClearSearch, onSearch }): JSX.Element => {
  const { formatMessage } = useIntl();
  const [filterValue, setFilterValue] = useState<string>('accountId');

  return (
    <SearchField.Root
      data-testid="search-field"
      width="22.75rem"
      height="3rem"
      onClear={onClearSearch}
      placeholder={formatMessage({ id: 'resolvedRequestsPage.commom.search' })}
      onSearch={onSearch}
    >
      <SearchField.Filter placeholder="Filter" value={filterValue} onChange={setFilterValue}>
        <SearchField.FilterOption value="accountId">POC ID</SearchField.FilterOption>
        <SearchField.FilterOption value="paymentId">Payment ID</SearchField.FilterOption>
      </SearchField.Filter>
    </SearchField.Root>
  );
};

export default Search;
