import { Edge, Node, useEdgesState, useNodesState } from '@xyflow/react';
import { useAppHeader } from 'admin-portal-shared-services';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAdapters } from 'services/workflow/getAdapters';
import { IAdapter, IAdaptersContext, IDialogConfig, IPaymentMethod } from 'types/adapter';
import { useEnvContext } from 'utils/EnvProvider';

const AdaptersContext = createContext<IAdaptersContext | undefined>(undefined);

export const AdaptersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { REACT_APP_CONFIGURATOR_API } = useEnvContext();

  const [adapters, setAdapters] = useState<IAdapter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [userCountry, setUserCountry] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<IAdapter | null>(null);
  const [formValues, setFormValues] = useState<Record<string, unknown>>({});
  const [pathSegments, setPathSegments] = useState<string[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>(null);

  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);

  useEffect(() => {
    const segments = location.pathname.split('/').filter(Boolean);
    setPathSegments(segments);
  }, [location]);

  const nodeIdRef = useRef<string | null>(null);
  const dialogConfigRef = useRef<IDialogConfig>({
    type: null,
    open: false,
    nodeId: null,
    edgeParams: null,
    selectedProviderId: null,
  });

  const [dialogConfigState, setDialogConfigState] = useState<IDialogConfig>({
    type: null,
    open: false,
    nodeId: null,
    edgeParams: null,
    selectedProviderId: null,
  });

  const [appHeaderConfig] = useAppHeader();

  const getProviderPayload = (providerId: string) => {
    const provider = adapters.find((adapter) => adapter.id === providerId);
    if (provider) {
      return Object.fromEntries(
        Object.entries(provider.payload).map(([key, value]) => [
          key,
          typeof value === 'string' && ['string', 'number', 'boolean'].includes(value) ? '' : value,
        ])
      );
    }
    return {};
  };

  useEffect(() => {
    if (appHeaderConfig) {
      // setUserCountry('PE'); // Uncomment this line to test on local env.
      setUserCountry(appHeaderConfig.selectedCountry);
    }
  }, [appHeaderConfig.selectedCountry]);

  useEffect(() => {
    const fetchAdapters = async () => {
      if (!userCountry) {
        setError(new Error('Country is not specified'));
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const result = await getAdapters(REACT_APP_CONFIGURATOR_API, userCountry);
        const filteredAdapters = result
          .filter((adapter) => adapter.countries.includes(userCountry))
          .map((adapter) => ({
            ...adapter,
            position: { x: 0, y: 0 },
          }));
        setAdapters(filteredAdapters);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdapters();
  }, [userCountry, REACT_APP_CONFIGURATOR_API]);

  const contextValue: IAdaptersContext = {
    adapters,
    loading,
    setLoading,
    error,
    setError,
    selectedProvider,
    setSelectedProvider,
    formValues,
    setFormValues,
    getProviderPayload,
    edges,
    setEdges,
    onEdgesChange,
    nodes,
    setNodes,
    onNodesChange,
    dialogConfigRef,
    dialogConfigState,
    setDialogConfigState,
    nodeIdRef,
    userCountry,
    pathSegments,
    paymentMethod,
    setPaymentMethod,
  };

  return <AdaptersContext.Provider value={contextValue}>{children}</AdaptersContext.Provider>;
};

export const useAdapters = () => {
  const context = useContext(AdaptersContext);
  if (!context) {
    throw new Error('useAdapters must be used within an AdaptersProvider');
  }
  return context;
};
