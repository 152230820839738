import { useContext } from 'react';

import { Alert, Divider, Heading, Paragraph } from '@hexa-ui/components';
import { Frequency } from 'components/Schedule/components/Frequency/Frequency';

import { Schedule } from 'components/Schedule/Schedule';

import { ModalContext } from 'contexts';
import { DownloadModal, ScheduledModal, ScheduleModal } from 'pages/Reports/components';
import { useScheduleModal } from 'pages/Reports/components/ScheduleModal/hooks/useScheduleModal';
import { useIntl } from 'react-intl';

import { Report } from 'components/Schedule/components/Report/Report';
import { filters } from 'components/Schedule/components/Summary/mocked';
import { Summary } from 'components/Schedule/components/Summary/Summary';
import { dropdownOptionsTime } from 'components/Schedule/components/Time/dataTime';
import { Time } from 'components/Schedule/components/Time/Time';
import { FrequencyDaysProps } from 'types/frequency';
import * as S from './ModalGlobal.styles';

const ModalGlobal = () => {
  const { scheduleModal, downloadModal, scheduledModal, schedule } = useContext(ModalContext);
  const { handleSelectDay, selectedDays } = useScheduleModal();
  const { formatMessage } = useIntl();

  return (
    <div data-testid="modal-testing-id">
      {downloadModal.isOpen && (
        <S.ModalContainer
          open={downloadModal.isOpen}
          data-testid="download-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DownloadModal />
        </S.ModalContainer>
      )}

      {scheduledModal.isOpen && (
        <S.ModalContainer
          open={scheduledModal.isOpen}
          data-testid="scheduled-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ScheduledModal />
        </S.ModalContainer>
      )}

      {scheduleModal.isOpen && (
        <S.ModalContainer
          open={scheduleModal.isOpen}
          data-testid="schedule-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ScheduleModal />
        </S.ModalContainer>
      )}
      {schedule.isOpen && (
        <S.ModalContainer
          open={schedule.isOpen}
          data-testid="schedule-new-schedule"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Schedule>
            <S.WrapperContent>
              <S.WrapperBox>
                <S.WrapperMessage>
                  <Alert
                    message={formatMessage({ id: 'resolvedRequestsPage.scheduleNew.message' })}
                  />
                </S.WrapperMessage>
                <S.WrapperFrequency>
                  <Heading size="H5">
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.fieldFrequency' })}
                  </Heading>
                  <Paragraph>
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.subFieldFrequency' })}
                  </Paragraph>
                  <S.WrapperItem>
                    {selectedDays.map((item: FrequencyDaysProps, index: number) => (
                      <Frequency
                        key={index}
                        handleSelectDay={() => handleSelectDay(item.name)}
                        name={item.name}
                        value={item.value}
                      />
                    ))}
                  </S.WrapperItem>
                </S.WrapperFrequency>

                <S.Box>
                  <Heading size="H5">
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.fieldDelivery' })}
                  </Heading>
                  <Time options={dropdownOptionsTime} />
                </S.Box>
                <S.Box>
                  <Report
                    label={
                      formatMessage({ id: 'resolvedRequestsPage.scheduleNew.report' }) ||
                      'Default Report Label'
                    }
                  />
                </S.Box>
              </S.WrapperBox>
              <Divider orientation="vertical" css={S.width} />
              <S.WrapperBox>
                <S.Box>
                  <Heading size="H4">
                    {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.filters' })}
                  </Heading>

                  <S.WrapperFilters>
                    {filters.map((filter) => (
                      <Summary
                        title={filter.title}
                        description={filter.description}
                        key={filter.id}
                      />
                    ))}
                  </S.WrapperFilters>
                </S.Box>
              </S.WrapperBox>
            </S.WrapperContent>
          </Schedule>
        </S.ModalContainer>
      )}
    </div>
  );
};

export default ModalGlobal;
