import { LabelGroup, LabelItem } from 'types/payments';

const labelInvoiceStatus = [
  {
    idName: 'invoiceStatusCancelled',
    label: 'Cancelled',
    value: 'CANCELLED',
  },
  {
    idName: 'invoiceStatusClosed',
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    idName: 'invoiceStatusDelivered',
    label: 'Delivered',
    value: 'DELIVERED',
  },
  {
    idName: 'invoiceStatusOpen',
    label: 'Open',
    value: 'OPEN',
  },
  {
    idName: 'invoiceStatusPending',
    label: 'Pending',
    value: 'PENDING',
  },
];

const labelOrderStatus = [
  {
    idName: 'orderStatusAvailable',
    label: 'Available',
    value: 'AVAILABLE',
  },
  {
    idName: 'orderStatusCancelledOrder',
    label: 'Cancelled',
    value: 'CANCELLED',
  },
  {
    idName: 'orderStatusConfirmed',
    label: 'Confirmed',
    value: 'CONFIRMED',
  },
  {
    idName: 'orderStatusDeliveredOrder',
    label: 'Delivered',
    value: 'DELIVERED',
  },
  {
    idName: 'orderStatusDenied',
    label: 'Denied',
    value: 'DENIED',
  },
  {
    idName: 'orderStatusExpired',
    label: 'Expired',
    value: 'EXPIRED',
  },
  {
    idName: 'orderStatusInvoiced',
    label: 'Invoiced',
    value: 'INVOICED',
  },
  {
    idName: 'orderStatusInTransit',
    label: 'In transit',
    value: 'IN_TRANSIT',
  },
  {
    idName: 'orderStatusModified',
    label: 'Modified',
    value: 'MODIFIED',
  },
  {
    idName: 'orderStatusPartialDelivery',
    label: 'Partial delivery',
    value: 'PARTIAL_DELIVERY',
  },
  {
    idName: 'orderStatusPending',
    label: 'Pending',
    value: 'PENDING',
  },
  {
    idName: 'orderStatusPendingCancelation',
    label: 'Pending cancelation',
    value: 'PENDING_CANCELLATION',
  },
  {
    idName: 'orderStatusPendingPayment',
    label: 'Pending Payment',
    value: 'PENDING_PAYMENT',
  },
  {
    idName: 'orderStatusPlaced',
    label: 'Placed',
    value: 'PLACED',
  },
];

const labelPaymentStatus = [
  {
    idName: 'paymentStatusExpired',
    label: 'Expired',
    value: 'EXPIRED',
  },
  {
    idName: 'paymentStatusFailed',
    label: 'Failed',
    value: 'FAILED',
  },
  {
    idName: 'paymentStatusPaid',
    label: 'Paid',
    value: 'PAID',
  },
];

const sortByLabel = (array: LabelItem[]): LabelItem[] =>
  array.slice().sort((a, b) => a.label.localeCompare(b.label));

const combineLabelsWithTitles = (labels: LabelItem[], title: string): LabelGroup => {
  return {
    title,
    options: sortByLabel(labels),
  };
};

export const combinedLabels: LabelGroup[] = [
  combineLabelsWithTitles(labelInvoiceStatus, 'Invoice Status'),
  combineLabelsWithTitles(labelOrderStatus, 'Order Status'),
  combineLabelsWithTitles(labelPaymentStatus, 'Payment Status'),
];
