import { Toast, ToastTypes, Tooltip } from '@hexa-ui/components';
import { Copy } from '@hexa-ui/icons';

import * as S from './CopyToClipboard.styles';
import { ICopyToClipboardProps } from 'types/copyToClipboard';
import { useState } from 'react';

const CopyToClipboard = ({
  hasTooltip = true,
  message,
  string,
}: ICopyToClipboardProps): JSX.Element => {
  const [toastProps, setToastProps] = useState<ToastTypes>({
    type: 'error',
    open: false,
    position: 'top-right',
    onOpenChange: () => false,
    message: '',
  });

  const handleOnOpenChange = (value: boolean) => {
    setToastProps((prevState) => ({
      ...prevState,
      open: value,
    }));
  };

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    if (!message) {
      return;
    }
    setToastProps({
      type: 'success',
      open: true,
      position: 'top-right',
      onOpenChange: handleOnOpenChange,
      message: message,
    });
  };

  const icon = (
    <Copy size="medium" onClick={() => copyToClipboard(string)} data-testid="copy-icon" />
  );

  return (
    <>
      <Toast.Root {...toastProps} />
      <S.Container data-testid="copy-to-clipboard">
        {hasTooltip ? (
          <Tooltip text={string} placement="bottom">
            <S.IconWrapper>{icon}</S.IconWrapper>
          </Tooltip>
        ) : (
          <S.IconWrapper>{icon}</S.IconWrapper>
        )}
      </S.Container>
    </>
  );
};

export { CopyToClipboard };
