import { Heading } from '@hexa-ui/components';
import { FC } from 'react';
import { SummaryProps } from 'types/summary';
import * as S from './Summary.styles';

export const Summary: FC<SummaryProps> = ({ title = '', description = '', ...rest }) => {
  return (
    <div data-testid="summary-container">
      {title && <Heading size="H5">{title}</Heading>}
      {description && <S.Text size="small">{description}</S.Text>}
    </div>
  );
};
