import { ColumnData } from 'pages/Reports/types/Reports.types';

export const columnsTable: ColumnData[] = [
  {
    Header: 'Payment ID',
    accessor: 'paymentId',
    key: 'PAYMENT_ID',
  },
  {
    Header: 'Order ID',
    accessor: 'orderNumber',
    key: 'ORDER_NUMBER',
  },
  {
    Header: 'Order value',
    accessor: 'orderValue',
    key: 'ORDER_VALUE',
  },
  {
    Header: 'Seller ID',
    accessor: 'sellerId',
    key: 'SELLER_ID',
  },
  {
    Header: 'Invoice date',
    accessor: 'invoiceDate',
    key: 'INVOICE_DATE',
  },
  {
    Header: 'POC ID',
    accessor: 'accountId',
    key: 'ACCOUNT_ID',
  },
  {
    Header: 'Invoice ID',
    accessor: 'invoiceNumber',
    key: 'INVOICE_NUMBER',
  },
  {
    Header: 'Payment value',
    accessor: 'paymentValue',
    key: 'PAYMENT_VALUE',
    // Cell: parsePaymentValueColumn,
  },
  {
    Header: 'Payment status',
    accessor: 'paymentStatus',
    key: 'PAYMENT_STATUS',
  },
  {
    Header: 'Method',
    accessor: 'paymentMethod',
    key: 'PAYMENT_METHOD',
  },
  {
    Header: 'Service provider',
    accessor: 'paymentMethodCode',
    key: 'PAYMENT_METHOD_CODE',
  },
  {
    Header: 'Order status',
    accessor: 'orderStatus',
    key: 'ORDER_STATUS',
  },
  {
    Header: 'Invoice value',
    accessor: 'invoiceValue',
    key: 'INVOICE_VALUE',
  },
  {
    Header: 'Invoice status',
    accessor: 'invoiceStatus',
    key: 'INVOICE_STATUS',
  },
  {
    Header: 'Payment date',
    accessor: 'paymentDate',
    key: 'PAYMENT_DATE',
  },
  {
    Header: 'Beneficiary name',
    accessor: 'beneficiaryName',
    key: 'BENEFICIARY_NAME',
  },
  {
    Header: 'Beneficiary ID',
    accessor: 'beneficiary',
    key: 'BENEFICIARY_ID',
  },
  {
    Header: 'Payment method fee',
    accessor: 'paymentMethodFee',
    key: 'PAYMENT_METHOD_FEE',
  },
  {
    Header: 'Commission fee',
    accessor: 'commissionFee',
    key: 'COMMISSION_FEE',
  },
];
