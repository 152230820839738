import { Paragraph } from '@hexa-ui/components';
import { FrequencyProps } from 'types/frequency';
import * as S from './Frequency.styles';

export const Frequency = ({
  handleSelectDay,
  value,
  name = '',
  ...rest
}: FrequencyProps): JSX.Element => {
  return (
    <S.WrapperFrequency
      {...rest}
      onClick={() => {
        try {
          handleSelectDay(name);
        } catch (error) {
          console.error('Error selecting day:', error);
        }
      }}
      selected={value}
      data-testid="frequency-container"
    >
      <Paragraph size="small">{name}</Paragraph>
    </S.WrapperFrequency>
  );
};
