import { Select } from '@hexa-ui/components';
import { Columns } from '@hexa-ui/icons';
import { columnsHideShowDropDown } from 'constants/columnsHideShowDropDown';
import { useSelector } from 'react-redux';
import { EditColumnsProps } from 'types/editColumns';
import { reportSelectedUserViewer } from 'store/selectors/report.selectors';
import { managerTypeUser } from 'constants/managerUserViewerType';

export const EditColumns = ({ columns, onChange }: EditColumnsProps) => {
  const onChangeHandle = (value: string[]) => {
    onChange(value);
  };

  const { Root, Option } = Select;
  const typeUser = useSelector(reportSelectedUserViewer);

  const allowedColumns = Array.isArray(managerTypeUser)
    ? managerTypeUser.find((userType) => typeUser?.includes(userType.type))?.key || []
    : [];

  const filteredColumns = columnsHideShowDropDown.filter((column) =>
    allowedColumns.includes(column.key)
  );

  const singleFilteredColumnDisabled = filteredColumns.length === 1;

  return (
    <Root
      id="edit-columns"
      icon={<Columns />}
      multiple
      value={columns.map((column) => column.key)}
      displayText="Edit columns"
      onChange={(value) => onChangeHandle(value)}
    >
      {filteredColumns.map((column) => (
        <Option key={`${column.key}`} value={column.key} disabled={singleFilteredColumnDisabled}>
          {column.label}
        </Option>
      ))}
    </Root>
  );
};
