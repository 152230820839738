import { styled } from '@hexa-ui/theme';

export const WrapperFrequency = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
  variants: {
    selected: {
      true: {
        border: '4px solid rgba(0, 110, 219, 1)',
        color: '#1999e1',
      },
      false: {
        border: '1px solid rgba(20, 20, 20, 0.12)',
      },
    },
  },
  defaultVariants: {
    selected: false,
  },
  padding: '10px 15px',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  width: 'var(--lineHeights-12)',
  height: 'var(--lineHeights-10)',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    border: '4px solid rgba(0, 110, 219, 1)',
    color: '#1999e1',
  },
  '.c-gzQrVK-bfAFXs-size-large': {
    width: '16px',
    height: '16px',
  },
  boxShadow: '$1',
});
