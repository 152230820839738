import { EmptyState, Pagination, Table } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { TableCustomProps } from 'types/tableContent';
import * as S from './TableContent.styles';

export function TableContent({
  dataTable,
  columns,
  isError,
  pagination,
}: Readonly<TableCustomProps>) {
  const { formatMessage } = useIntl();

  if (isError) {
    return (
      <Table
        pagination={false}
        columns={columns}
        data={dataTable}
        emptyMessage={
          <EmptyState.SectionLevel
            description={formatMessage({ id: 'ErrorMessages.ERROR_FETCH_DATA' })}
          />
        }
      />
    );
  }

  return (
    <>
      <Table
        pagination={false}
        columns={columns}
        data={dataTable}
        emptyMessage={formatMessage({ id: 'ErrorMessages.ERROR_FETCH_DATA' })}
      />
      <S.PaginationContainer>
        <Pagination
          defaultPageSize={25}
          defaultCurrent={1}
          showPageSizeSelector={false}
          {...pagination}
        />
      </S.PaginationContainer>
    </>
  );
}
