import { Chip, TextButton } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { useMemo } from 'react';

import { formatToDateTime, getLocalizedDate } from 'utils/date';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { Filters, defaultInitialFilterValues } from '../Filter/FilterContext';
import { ChipsContainer } from './SelectedValues.styles';

interface SelectedValuesProps {
  onApplyFilters?: (filters: Filters) => void;
  onClearFilters?: () => void;
  filterValues?: Filters;
}

export const SelectedValues = ({
  onApplyFilters,
  onClearFilters,
  filterValues,
}: SelectedValuesProps) => {
  const { preferredLanguage } = usePreferredLanguageV2();
  const { formatMessage } = useIntl();

  const values: Array<{ key: string; label: string; onDelete: () => void }> = useMemo(() => {
    const handlers = {
      vendor: (value: Filters['vendor']) => {
        if (!value?.value) return;
        return {
          label: `${formatMessage({ id: 'filter.vendor' })} = ${value.label}`,
          onDelete: () =>
            onApplyFilters({ ...filterValues, vendor: defaultInitialFilterValues['vendor'] }),
        };
      },
      status: (value: Filters['status']) => {
        if (!value?.value) return;
        return {
          label: `${formatMessage({ id: 'filter.status' })} = ${value.label}`,
          onDelete: () =>
            onApplyFilters({ ...filterValues, status: defaultInitialFilterValues['status'] }),
        };
      },
      serviceName: (value: Filters['serviceName']) => {
        if (!value?.value) return;
        return {
          label: `${formatMessage({ id: 'filter.tool' })} = ${value.label}`,
          onDelete: () =>
            onApplyFilters({
              ...filterValues,
              serviceName: defaultInitialFilterValues['serviceName'],
            }),
        };
      },
      fromCreatedAt: (value: Filters['fromCreatedAt']) => {
        if (!value) return;

        const dates = [
          formatToDateTime(value, preferredLanguage),
          filterValues.toCreatedAt && formatToDateTime(filterValues.toCreatedAt, preferredLanguage),
        ]
          .filter(Boolean)
          .map((date) => getLocalizedDate(date, preferredLanguage));

        return {
          label: `${formatMessage({ id: 'filter.createdOn' })} = ${dates.join(' - ')}`,
          onDelete: () =>
            onApplyFilters({
              ...filterValues,
              fromCreatedAt: defaultInitialFilterValues['fromCreatedAt'],
              toCreatedAt: defaultInitialFilterValues['toCreatedAt'],
            }),
        };
      },
      fromUpdatedAt: (value: Filters['fromUpdatedAt']) => {
        if (!value) return;

        const dates = [
          formatToDateTime(value, preferredLanguage),
          filterValues.toUpdatedAt && formatToDateTime(filterValues.toUpdatedAt, preferredLanguage),
        ]
          .filter(Boolean)
          .map((date) => getLocalizedDate(date, preferredLanguage));

        return {
          label: `${formatMessage({ id: 'filter.lastEdited' })} = ${dates.join(' - ')}`,
          onDelete: () =>
            onApplyFilters({
              ...filterValues,
              fromUpdatedAt: defaultInitialFilterValues['fromUpdatedAt'],
              toUpdatedAt: defaultInitialFilterValues['toUpdatedAt'],
            }),
        };
      },
    };

    return Object.entries(filterValues).reduce((acc, [key, value]) => {
      const data = handlers[key as keyof Filters]?.(value);
      if (data) acc.push({ ...handlers[key as keyof Filters](value), key });
      return acc;
    }, []);
  }, [filterValues]);

  if (!values.length) return null;

  return (
    <ChipsContainer data-testid="selected-values">
      {values.map((item) => (
        <Chip.Root key={item.key}>
          <Chip.Label>{item.label}</Chip.Label>
          <Chip.DeleteIcon onDelete={item.onDelete} />
        </Chip.Root>
      ))}
      <TextButton iconPosition="leading" icon={X} onClick={onClearFilters}>
        {formatMessage({ id: 'filter.clearFilters' })}
      </TextButton>
    </ChipsContainer>
  );
};
