import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ModalWrapper = styled('div', {
  '.c-ktUxKa-eYaSdF-shouldAdjustPadding-true': {
    padding: '1rem 2rem 0 2rem',
  },
  '.c-kMMXDz-fKTZlZ-variant-overlay': {
    width: '50%',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
  },
  '.c-hkjiru': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  '.c-eMgPXw': {
    padding: '0.5rem 1.5rem 0px',
  },
});

export const Title = styled('h1', {
  fontFamily: 'var(--fonts-heading)',
  color: 'var(--colors-neutral100)',
});

export const Description = styled(Paragraph, {
  fontWeight: 'var(--fontWeights-normal)',
  lineHeight: 'var(--lineHeights-6)',
  fontFamily: 'var(--fonts-paragraph)',
  color: 'var(--colors-interfaceLabelSecondary) !important',
  marginTop: '0.7rem',
});

export const ContainerHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'var(--fonts-heading)',
});

export const TextContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const ContentHeader = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const MainContentWrapper = styled('div', {});

export const Box = styled('div', {
  display: 'flex',
  gap: '0.625rem',

  '.c-cImTRJ-dUxxYp-size-medium': {
    padding: '0px 24px 0px 24px',
  },
});

export const footerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});
