import { Button, IconButton, Modal as ModalComponent } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { ModalContext } from 'contexts';
import { useCallback, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ScheduleProps } from 'types/schedule';
import * as S from './Schedule.styles';

export const Schedule = ({ ref, children }: ScheduleProps): JSX.Element => {
  const firstInteractiveElementRef = useRef<HTMLButtonElement>(null);
  const { formatMessage } = useIntl();

  const { schedule } = useContext(ModalContext);

  const handleClose = useCallback(() => {
    schedule.setOpen(false);
  }, [schedule]);

  const { Root: Modal } = ModalComponent;

  return (
    <S.ModalWrapper data-testid="schedule" ref={ref}>
      <Modal
        open={schedule.isOpen}
        actions={null}
        title={
          <S.ContainerHeader>
            <S.Title>
              {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.titleHeader' })}
            </S.Title>

            <IconButton onClick={handleClose} size="small" variant="tertiary" icon={X} />
          </S.ContainerHeader>
        }
        aria-describedby="modal-description"
      >
        <S.Description>
          {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.description' })}
        </S.Description>
        <S.MainContentWrapper>{children}</S.MainContentWrapper>

        <S.footerContainer>
          <S.Box>
            <Button size="medium" variant="secondary" onClick={() => schedule.setOpen(false)}>
              {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.cancelButton' })}
            </Button>
            <Button ref={firstInteractiveElementRef} size="medium">
              {formatMessage({ id: 'resolvedRequestsPage.scheduleNew.sendButton' })}
            </Button>
          </S.Box>
        </S.footerContainer>
      </Modal>
    </S.ModalWrapper>
  );
};
