import { createHourOption } from 'pages/Reports/utils/createHour';

export const dropdownOptionsTime = Array.from({ length: 24 }, (_, i) => createHourOption(i));

export const daysOfWeekValues = Object.freeze([
  { name: 'Mon', key: 'MONDAY', value: false },
  { name: 'Tue', key: 'TUESDAY', value: false },
  { name: 'Wed', key: 'WEDNESDAY', value: false },
  { name: 'Thu', key: 'THURSDAY', value: false },
  { name: 'Fri', key: 'FRIDAY', value: false },
]);

export const fileFormatValues = Object.freeze([{ name: '.csv', key: 'CSV', value: false }]);
