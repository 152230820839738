import { ThemeProvider, ThemeProviderType } from '@hexa-ui/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsPerClusterProvider, useHubTheme } from 'admin-portal-shared-services';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Toast } from '@hexa-ui/components';

import './App.styles.css';

import { AuthProvider, ModalProvider } from 'contexts';
import { AdaptersProvider } from 'contexts/AdaptersContext';
import IntlProvider from 'i18n/provider';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'src/routes';
import { ApolloProviderWrapper } from 'src/providers';
import { EnvConfig, EnvProvider } from 'utils/EnvProvider';
import { SetThemeProvider } from 'utils/Theme';
import { AppbarConfig, FilterContextProvider, ErrorFallback } from 'components';

const queryClient = new QueryClient();

export default function App(props: Readonly<EnvConfig>) {
  const [hubTheme, setHubTheme] = useState<ThemeProviderType['theme']>('customer');
  useHubTheme(hubTheme);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EnvProvider env={props}>
          <PropsPerClusterProvider propValues={props}>
            <ThemeProvider theme={hubTheme}>
              <SetThemeProvider setTheme={setHubTheme}>
                <AuthProvider>
                  <Provider store={store}>
                    <IntlProvider>
                      <ModalProvider>
                        <BrowserRouter>
                          <AdaptersProvider>
                            <AppbarConfig>
                              <Toast.Provider duration={5000} swipeDirection="right">
                                <FilterContextProvider>
                                  <ApolloProviderWrapper>
                                    <AppRoutes />
                                  </ApolloProviderWrapper>
                                </FilterContextProvider>
                              </Toast.Provider>
                            </AppbarConfig>
                          </AdaptersProvider>
                        </BrowserRouter>
                      </ModalProvider>
                    </IntlProvider>
                  </Provider>
                </AuthProvider>
              </SetThemeProvider>
            </ThemeProvider>
          </PropsPerClusterProvider>
        </EnvProvider>
      </ErrorBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
