import MessageMap from 'i18n/i18n.d';

const ptBr: MessageMap = {
  Commons: {
    actions: 'Ações',
    edit: 'Editar',
    delete: 'Remover',
  },
  ErrorMessages: {
    DATA_NOT_FOUND: 'Os dados não foram carregados.',
    ERROR_FETCH_DATA:
      'Ocorreu um erro ao tentar buscar os dados. Atualize a página e tente novamente.',
  },
  PageTitles: {
    WORKFLOW: 'Fluxo de Trabalho',
    CREATE: 'Criar novo Fluxo de Trabalho',
    PAYMENTS: 'Pagamentos',
    TRANSACTIONS: 'Transactions',
  },
  downloadModal: {
    titleHeader: 'Relatório por email',
    description: 'O arquivo contendo os dados filtrados será enviado para o seu email corporativo.',
    fieldEmail: 'Seu email',
    fieldFileFormat: 'Formato',
    sendButton: 'Enviar',
    cancelButton: 'Voltar',
  },
  scheduleModal: {
    titleHeader: 'Agendar envio',
    description: 'Receba o arquivo em seu email',
    fieldFileFormat: 'Formato',
    fieldFrequency: 'Frequência',
    subFieldFrequency: 'Recorre toda semana em:',
    fieldDelivery: 'Horário de envio',
    fieldEmail: 'Email',
    sendButton: 'Agendar',
    cancelButton: 'Cancelar',
  },
  reportsPage: {
    pageTitle: 'Relatórios',
    downloadButton: 'Download',
    scheduleButton: 'Agendar',
    filterButton: 'Filtrar',
    table: {
      header: {
        date: 'Data',
        description: 'Descrição',
        amount: 'Valor',
        status: 'Status',
        actions: 'Ações',
      },
    },
  },
  filter: {
    all: 'Todos',
    paymentMethod: 'Método de pagamento',
  },
  workflow: {
    optionalButton: {
      label: 'Método de pagamento',
    },
  },
  createWorkflowPage: {
    publishWorkflow: 'Publicar',
    paymentMethod: 'Método de pagamento:',
    sideMenu: {
      label: 'Selecione um provedor',
      addProviderButton: 'Adicionar',
      emptySelect: 'Por favor, selecione um provedor antes de adicionar.',
      providerAlreadyAdded: 'Provedor {providerName} já adicionado',
      errorMessage: 'Nenhum adaptador disponível. Selecione um país diferente.',
    },
    providerCard: {
      succeeded: 'Sucesso',
      declined: 'Recusado',
      error: 'Erro',
    },
    connectProvidersDialog: {
      createTitle: 'Criar adaptador',
      editTitle: 'Editar adaptador',
      tooltip: 'Duplicar',
      inputPlaceholder: 'Valor de destino',
      button: {
        primary: 'Salvar',
        secondary: 'Cancelar',
      },
      errorFetchingPayload: 'Erro ao buscar payload do provedor.',
      invalidInput: 'Entrada inválida.',
      noPayloadAvailable: 'Nenhum payload disponível.',
    },
    removeProviderDialog: {
      title: 'Remover provedor',
      content: 'Tem certeza de que deseja remover este provedor?',
      button: {
        primary: 'Remover',
        secondary: 'Cancelar',
      },
    },
    removeEdgeDialog: {
      title: 'Remover conexão',
      content: 'Tem certeza de que deseja remover esta conexão?',
      button: {
        primary: 'Remover',
        secondary: 'Cancelar',
      },
    },
    editOrRemoveDialog: {
      title: 'Editar ou remover?',
      content: 'Você gostaria de editar a conexão entre os dois provedores ou removê-la?',
      button: {
        primary: 'Editar',
        secondary: 'Remover',
      },
      removeEdgeDialog: {
        title: 'Remover conexão',
        content: 'Tem certeza de que deseja remover esta conexão?',
      },
    },
  },
  homePage: {
    pageTitle: 'Bem-vindo ao Payments Hub',
  },
};

export default ptBr;
