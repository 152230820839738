import { Filter } from './Filter';
import { SelectedValues } from './SelectedValues';
import { Search } from '../Search';
import { EditColumns } from 'components';
import * as S from './FilterBar.styles';
import { FilterBarProps } from 'types/filterBar';

const FilterBar = ({
  onClearSearch,
  defaultPage,
  onSearch,
  onApplyFilters,
  onClearFilters,
  filterValues,
  editColumns,
  onChange,
}: FilterBarProps): JSX.Element => {
  return (
    <>
      <S.Container>
        <S.Content>
          <Filter
            defaultPage={defaultPage}
            onApplyFilters={onApplyFilters}
            onClearFilters={onClearFilters}
            filterValues={filterValues}
          />
          <Search onClearSearch={onClearSearch} onSearch={onSearch} />
        </S.Content>
        <S.EditColumnsWrapper>
          <EditColumns columns={editColumns} onChange={onChange} />
        </S.EditColumnsWrapper>
      </S.Container>
      <SelectedValues
        onApplyFilters={onApplyFilters}
        onClearFilters={onClearFilters}
        filterValues={filterValues}
      />
    </>
  );
};

export default FilterBar;
